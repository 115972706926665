'use client'
import { Box, VStack, Heading, Text, Show, Skeleton } from "@chakra-ui/react";

// Componentes comuns
import Head from "next/head";

// Carrega todos os componentes dinâmicos
import ConteudoImg1 from "./components/ConteudoImg1";
import ConteudoImg2 from "./components/ConteudoImg2";
import ConteudoImg3 from "./components/ConteudoImg3";

import ConteudoImgSlider from "./components/ConteudoImgSlider"

import SliderPortfolio1 from "./components/SliderPortfolio1";
import SliderPortfolio2 from "./components/SliderPortfolio2";

import ConteudoVid1 from "./components/ConteudoVid1";

import ConteudoIcone1 from "./components/ConteudoIcone1";
import ConteudoIcone2 from "./components/ConteudoIcone2";
import ConteudoIcone3 from "./components/ConteudoIcone3";

import ConteudoBrasalNumeros from "./components/ConteudoBrasalNumeros";

import HeadingText1 from "./components/HeadingText1";
import ConteudoTexto1 from "./components/ConteudoTexto1";
import ConteudoAccordion1 from "./components/ConteudoAccordion1";
import ConteudoAccordion2 from "./components/ConteudoAccordion2";
import ConteudoAba from "./components/ConteudoAba";

//import BlogPosts from "./components/BlogPosts";

import HeaderComponent from "./components/header/page";
import FooterComponent from "./components/footer/page";
import ConteudoVidSlider from "./components/ConteudoVidSlider";
import ConteudoHistoriaTimeline from "./components/ConteudoHistoriaTimeline";
import ConteudoTitlePage from "./components/ConteudoTitlePage";
import { getCabecalho, getConfig } from '@/app/api/home'
import React, { ReactNode, useEffect, useState } from "react";
import ConteudoFormulario from "./components/ConteudoFormulario";
import BlogPosts from "@/components/blog/BlogPosts";
import Consent from "@/components/Consent";



const DinamicPage = (props: any): ReactNode => {

  const { dataPage: page, dataNews, cabecalho, footer, config, menuRodape, formulario } = props
  const [dataPage, setData] = useState<any>()

  useEffect(() => {
    if (page) {
      setData(page)
    }
  }, [page])

  if (!dataPage) {
    return <></>
  }

  return (
    <React.Fragment>
      <Head>
        <title>{dataPage?.attributes?.Titulo}</title>
      </Head>

      <HeaderComponent localizations={dataPage?.attributes?.localizations} cabecalho={cabecalho} />
      <Box minH={220} maxH={460}>
        <Box
          w={'full'}
          bgSize={'cover'}
          bgImage={{
            base: dataPage?.attributes?.ImagemMobile?.data?.attributes?.url,
            sm: dataPage?.attributes?.ImagemMobile?.data?.attributes?.url,
            md: dataPage?.attributes?.ImagemDesktop?.data?.attributes?.url,
            lg: dataPage?.attributes?.ImagemDesktop?.data?.attributes?.url,
            xl: dataPage?.attributes?.ImagemDesktop?.data?.attributes?.url,
            '2xl': dataPage?.attributes?.ImagemDesktop?.data?.attributes?.url,
          }}
          h={{
            base: 200,
            sm: 200,
            md: 440,
            lg: 440,
            xl: 440,
            '2xl': 440,
          }}
          p={10}
          className="div-header" />
      </Box>
      <Box mt={'80px'} className="div-tit-header">
        <ConteudoTitlePage data={{ ...dataPage?.attributes, Alinhamento: 'Centro' }} />
      </Box>
      {dataPage?.attributes?.Conteudo.map((component: any, index: number) => {

        switch (component.__component) {
          case 'page.conteudo1':
            return <ConteudoImg1 key={'page_conteudo-img1_' + index} data={component} />
          case 'page.conteudo-img2':
            return <ConteudoImg2 key={'page_conteudo-img2_' + index} data={component} />
          case 'page.conteudo-img3':
            return <ConteudoImg3 key={'page_conteudo-img3_' + index} data={component} />
          case 'page.conteudo-img-slider':
            return <ConteudoImgSlider key={'page_conteudo-img-slider_' + index} data={component} />
          case 'page.slider-portfolio1':
            return <SliderPortfolio1 key={'page_slide-port1_' + index} data={component} />
          case 'page.slider-portfolio2':
            return <SliderPortfolio2 key={'page_slide=port2_' + index} data={component} />
          case 'page.conteudo-vid1':
            return <ConteudoVid1 key={'page_conteudo_video_' + index} data={component} />
          case 'page.conteudo-vid-slider':
            return <ConteudoVidSlider key={'page_conteudo_video_slider_' + index} data={component} />
          case 'page.conteudo-icone1':
            return <ConteudoIcone1 key={'page_conteudo-icon1_' + index} data={component} />
          case 'page.conteudo-icone2':
            return <div key={'page_conteudo_icon2_' + index}></div>
          //return <ConteudoIcone2 key={'page_conteudo_icon2_'+index} data={component} />
          case 'page.conteudo-icone3':
            return <ConteudoIcone3 key={'page_conteudo_icon3_' + index} data={component} />
          case 'page.conteudo-brasal-numeros':
            return <ConteudoBrasalNumeros key={'page_conteudo_brasal_numeros_' + index} data={component} />
          case 'page.heading-texto1':
            return <HeadingText1 key={'page_heading_texto1_' + index} data={component} />
          case 'page.conteudo-texto1':
            return <ConteudoTexto1 key={'page_conteudo_texto1_' + index} data={component} />
          case 'page.conteudo-historia-timeline':
            return <ConteudoHistoriaTimeline key={'page_conteudo_hist_timeline1_' + index} data={component} />
          //return <ConteudoAccordion1 key={'page_conteudo_hist_timeline1_'+index} data={component} />
          case 'page.conteudo-accordion1':
            return <ConteudoAccordion1 key={'page_conteudo_accordion1_' + index} data={component} />
          case 'page.conteudo-accordion2':
            return <div key={'page_conteudo_accordion2_' + index}></div> /*<ConteudoAccordion2 key={'page_conteudo_accordion2_'+index} data={component} />*/
          case 'page.blog-posts':
            //return <Box key={'page_blog_posts_'+index}>teste</Box>
            return <BlogPosts key={'page_blog_posts_' + index} data={component} news={dataNews} config={config?.data?.attributes} />
          case 'page.aba-conteudo1':
            return <ConteudoAba key={'page_aba_conteudo_' + index} data={component} />
          case 'page.conteudo-formulario':
            return <ConteudoFormulario key={'page_conteudo_formulario_' + index} formulario={formulario} data={component} />
          default:
            return <Box key={'nao-encontrado-' + index}>Componente não encontrado: {component.__component}</Box>
        }
      })}
      <FooterComponent footer={footer} config={config} menu={menuRodape} />
      <Consent />
    </React.Fragment>
  );
}

export default DinamicPage;
