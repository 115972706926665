import { containerBreakpoints } from "@/components/breakpoints"
import { Box, Text, Heading, Container } from "@chakra-ui/react"
import InnerHtml from "./innerHtml"

const ConteudoTitlePage = (props: any) => {
    const { data } = props

    const before = {
        position: 'absolute',
        top: 0,
        content: '""',
        width: '7px',
        height: '7px',
        display: 'block',
        backgroundColor: '#cc0007',
        borderRadius: '50%',
        marginBottom: '16px'
    }
    const beforeCenter = { ...before, ...{ left: '50%' } }
    const beforeLeft = { ...before, ...{ left: 0 } }
    const beforeRight = { ...before, ...{ right: 0 } }

    const getSubtitleDecoration = () => {
        if(data.Alinhamento === 'Direita') {
            return beforeRight
        }
        if(data.Alinhamento === 'Centro') {
            return beforeCenter
        }
        return beforeLeft
    }

    const getTextAlign = () => {
        if(data.Alinhamento === 'Direta') {
            return 'end'
        }
        if(data.Alinhamento === 'Centro') {
            return 'center' 
        }
        return 'start'
    }


    return (
        <Box as="section" className={data?.ClassName} my={'100px'}>
            <Container maxW={containerBreakpoints}>
                {data?.Subtitulo && 
                <Text as='h5' 
                    _before={getSubtitleDecoration()} pos='relative' 
                    pt={'16px'} textAlign={getTextAlign()} 
                    w={'100%'} 
                    fontSize={'14px'} 
                    lineHeight={1.2} 
                    textTransform={'uppercase'} 
                    letterSpacing={'1.4px'} fontWeight={'500'} margin={'0 0 7px'} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                    {data?.Subtitulo}
                </Text>
                }
                {data?.Titulo && 
                <Heading as='h1' mb={10} 
                    fontSize={'50px'} 
                    letterSpacing={'-2px'} 
                    textAlign={getTextAlign()} 
                    fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                    {data?.Titulo}
                </Heading>
                }
                <InnerHtml fontSize={'17px'} lineHeight={'29px'} pb={'10px'} fontWeight={500} textAlign={getTextAlign()} html={data?.Descricao} />
            </Container>
        </Box>
    )
}

export default ConteudoTitlePage