import { Box, Collapse, Heading, Image, useDisclosure } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaAddressBook } from 'react-icons/fa';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './time-line.css'
import React, { useEffect, useState } from 'react';

const pascalToSnakeCase = (s: string): any => {
    const value = s.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return "-" + y.toLowerCase() })
        .replace(/^_/, "")
        .replace("-fa-", "")
    if (value == 'gulp')
        return 'car'
    return value
}

const YearItem = ({ year }: { year: string }) => {
    const YearIcon = () => (
        <Box display={'flex'} w={'100%'} h={'100%'} justifyContent={'center'} alignItems={'center'} fontSize={'15px'} fontWeight={'bold'}>
            {year}
        </Box>
    )
    return (
        <VerticalTimelineElement
            className="year-group"
            iconStyle={{ background: 'rgb(173, 173, 173)', color: '#fff',  }}
            icon={<YearIcon />}
        />
    )
}

const TimelineItem = ({ timelineItem, index }: { timelineItem: any, index: number }) => {
    const {isOpen, onToggle} = useDisclosure()

    const className = (timelineItem?.Segmento?.data?.attributes?.ClasseCss||'') + 
        (index % 2 ? ' vertical-timeline-element--left' : ' vertical-timeline-element--right')

    return (
        <VerticalTimelineElement
            className={className}
            date={timelineItem.Ano}
            contentStyle={{ background: '#ebebeb', color: '#000' }}
            contentArrowStyle={{ borderRight: '7px solid #ebebeb' }}
            iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff', width: '40px', height: '40px', marginLeft: '-20px' }}
            icon={<FontAwesomeIcon icon={["fas", pascalToSnakeCase(timelineItem.Icone)]} />}
        >
            <Box display={'flex'} flexWrap={'wrap'} flexDirection={index % 2 ? 'row-reverse' : 'row'}>
                <Box
                    className={`coluna-imagem`} 
                    w={{sm:'100%', md:'50%', lg: '50%', xl:'50%', '2xl': '50%'}} 
                    mb={{ base: undefined, sm:'10px', md:undefined, lg: undefined, xl:undefined, '2xl': undefined}}
                    display={'flex'}
                    flexWrap={'wrap'} 
                    justifyContent={index % 2 ? 'end' :  'start'} >
                    <Box w={'200px'}
                        h={'200px'}
                        p={'10px'}
                        className={timelineItem?.Segmento?.data?.attributes?.ClasseCss||''}
                        position={'relative'}
                        rounded={'full'}
                        bgColor={'red.300'}
                        _after={{
                            content: '""',
                            position: 'absolute',
                            backgroundColor: 'red.300',
                            width: '50%',
                            height: '50%',
                            top: 0,
                            left: index % 2 ? 0 : false,
                            right: !(index % 2) ? 0 : false,
                            zIndex: 0,

                        }}>
                        <Image alt=""
                            rounded={'full'}
                            border={'10px solid #fff'}
                            width={'100%'}
                            height={'100%'}
                            objectFit={'cover'}
                            src={timelineItem?.Imagem?.data?.attributes?.url}
                            zIndex={1}
                            position={'relative'} />

                    </Box>
                </Box>
                <Box w={{sm:'100%', md:'50%', lg: '50%', xl:'50%', '2xl': '50%'}} display={'flex'} flexDirection={'column'} flexWrap={'nowrap'}>
                    {timelineItem?.Segmento?.data &&
                        <Box className={'segmento-badge'} color={'white'} alignSelf={'start'} fontWeight={'800'} as='div' bgColor={'red.300'} py={'5px'} px={'10px'} rounded={'full'}>
                            {timelineItem?.Segmento?.data?.attributes?.Nome}
                        </Box>
                    }
                    <Heading className='titulo'>{timelineItem?.Titulo}</Heading>
                    <Collapse startingHeight={70} in={isOpen} >
                        <span className='descricao' dangerouslySetInnerHTML={{ __html: timelineItem?.Descricao }}></span>
                    </Collapse>
                    <span className='leia_mais' onClick={onToggle}>leia {isOpen ? '-' : '+'}</span>
                </Box>
            </Box>
        </VerticalTimelineElement>
    )
}

const ConteudoHistoriaTimeline = ({ data }: { data: any }) => {

    const [timelineItems, setTimelineItems] = useState<{ [key: string]: any[] }>({})

    const group = () => {
        let newData: { [key: string]: any[] } = {}
        data?.Timeline.forEach((i: any) => {
            if (!newData.hasOwnProperty(i.Ano)) {
                newData[i.Ano] = []
            }
            newData[i.Ano].push(i)
        })
        setTimelineItems(newData)
    }

    useEffect(() => {
        group()
    }, [])

    const getItemIndex = (item:any) => {
        return data?.Timeline.findIndex((i:any) => i.id == item.id)
    } 

    return <>
        <Box>
            <VerticalTimeline animate={false} className='vertical-timeline-custom-line'>
                {Object.keys(timelineItems).reverse()?.map((item: string, indexGroup: number) => {
                    return (
                        <React.Fragment key={item}>
                            <YearItem year={item} />
                            {timelineItems[item].map((timelineItem: any, indexItem: number) => {
                                return <TimelineItem 
                                    key={`timeline-item-${timelineItem?.id}`} 
                                    index={getItemIndex(timelineItem)}
                                    timelineItem={timelineItem} />
                            })}
                        </React.Fragment>
                    )
                })}

            </VerticalTimeline>
        </Box>

    </>
}

export default ConteudoHistoriaTimeline


