
import BannerComponent from '@/components/footer/banner'
import FooterComponent from '@/components/footer/footer'
import { ReactNode } from 'react'


const FooterSlot = (props:any):ReactNode  => {
  const {footer, config, menu} = props
  return (
    <>
      <BannerComponent data={footer.NuvemPalavras} />
      <FooterComponent data={footer} config={config?.data?.attributes} menu={menu} />
    </>
  )
}

export default FooterSlot
