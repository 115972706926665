'use client'
import {useRouter} from 'next/navigation'
import InputMask from "react-input-mask";
import { containerBreakpoints } from "@/components/breakpoints"
import { Box, Text, Heading, Container, Button, FormControl, FormLabel, Input, Select, Checkbox, Textarea, VStack, FormErrorMessage } from "@chakra-ui/react"
import InnerHtml from "./innerHtml"
import { useEffect, useRef, useState } from "react";
import _ from "underscore";
import send from "@/app/api/formSubmit";
import CustonButton from "@/components/button";


const ConteudoFormulario = (props: any) => {
    const { data, formulario } = props
    let { formulario:{form_build:{fields}} } = props
    const form = useRef(null)
    const router = useRouter()
    const [loading, setLoading] = useState(false)

    //console.log(formulario)

    const before = {
        position: 'absolute',
        top: 0,
        content: '""',
        width: '7px',
        height: '7px',
        display: 'block',
        backgroundColor: '#cc0007',
        borderRadius: '50%',
        marginBottom: '16px'
    }
    const beforeCenter = { ...before, ...{ left: '50%' } }
    const beforeLeft = { ...before, ...{ left: 0 } }
    const beforeRight = { ...before, ...{ right: 0 } }

    const setFieldValue = (name: string, value: any) => {
        _.filter(fields, (item: any) => {
            if(item.name === name) {
                item.value = value
            }
        }
        )
    }

    const getSubtitleDecoration = () => {
        if(data.Alinhamento === 'Direita') {
            return beforeRight
        }
        if(data.Alinhamento === 'Centro') {
            return beforeCenter
        }
        return beforeLeft
    }

    const getTextAlign = () => {
        if(data.Alinhamento === 'Direta') {
            return 'end'
        }
        if(data.Alinhamento === 'Centro') {
            return 'center' 
        }
        return 'start'
    }

    function isValiEmail(val:string) {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(val)) {
          return 'Invalid Email Address';
        }
      }

    const getFormField = (field: any, index: number) => {
        switch (field.type) {
            case 'text':
                return (
                    <FormControl variant={'horizontal'} isRequired={field.required} my={10} id={field.id} key={`form-field-${index}`}>
                        <FormLabel>{field.label}</FormLabel>
                        <Input onChange={(e)=>{
                            setFieldValue(field.name, e.target.value)
                        }} variant={'filled'} name={field.name} type="text" />
                    </FormControl>
                )
            case 'email':
                return (
                    <FormControl isRequired={field.required} my={10} id={field.id} key={`form-field-${index}`}>
                        <FormLabel>{field.label}</FormLabel>
                        <Input onChange={(e)=>{
                            setFieldValue(field.name, e.target.value)
                        }}  variant={'filled'} name={field.name} type="email" />
                        <FormErrorMessage>{isValiEmail(field.value)}</FormErrorMessage>
                    </FormControl>
                )
            case 'number':
                return (
                    <FormControl isRequired={field.required} my={10} id={field.id} key={`form-field-${index}`}>
                        <FormLabel>{field.label}</FormLabel>
                        <Input onChange={(e)=>{
                            setFieldValue(field.name, e.target.value)
                        }}  variant={'filled'} name={field.name} type="number" />
                    </FormControl>
                )
            case 'tel':
                    return (
                        <FormControl isRequired={field.required} my={10} id={field.id} key={`form-field-${index}`}>
                            <FormLabel>{field.label}</FormLabel>
                            <Input onChange={(e)=>{
                            setFieldValue(field.name, e.target.value)
                        }}  as={InputMask} mask={'(99) 99999-9999'} variant={'filled'} name={field.name} type="tel" />
                        </FormControl>
                    )
                case 'textarea':
                return (
                    <FormControl isRequired={field.required} my={10} id={field.id} key={`form-field-${index}`}>
                        <FormLabel>{field.label}</FormLabel>
                        <Textarea onChange={(e)=>{
                            setFieldValue(field.name, e.target.value)
                        }}  rows={5} variant={'filled'} name={field.name} />
                    </FormControl>
                )
            case 'select':
                return (
                    <FormControl isRequired={field.required} my={10} id={field.id} key={`form-field-${index}`}>
                        <FormLabel>{field.label}</FormLabel>
                        <Select onChange={(e)=>{
                            setFieldValue(field.name, e.target.value)
                        }}  variant='filled' name={field.name}>
                            {field.options.map((option: any, index: number) => {
                                return (
                                    <option key={`form-field-${index}`} value={option}>{option}</option>
                                )
                            })}
                        </Select>
                    </FormControl>
                )
            case 'checkbox':
                return (
                    <FormControl isRequired={field.required} my={10} id={field.id} key={`form-field-${index}`}>
                        <FormLabel>{field.label}</FormLabel>
                        <VStack>
                        {field.options.map((option: any, index: number) => {
                                return (
                                    <Checkbox onChange={(e)=>{
                                        setFieldValue(field.name, e.target.value)
                                    }} name={field.name} key={`form-field-${index}`} value={option}>{option}</Checkbox>
                                )
                            })}
                        </VStack>
                    </FormControl>
                )
            default:
                return null
        }
    }

    const submitForm = async (fields:any) => {
        try {
            setLoading(true)
            const data:any = {
                captchaToken:'123',
                formId:formulario.id,
                formData:{}
            }
            for (const pair of fields.entries()) {
                data.formData[pair[0]] = pair[1];
            }
            const result = await send(data)
            //console.log(result)
            router.replace(formulario.redirect_page_slug)
        } catch(e) {

        } finally {
            setLoading(false)
        }
    }

    return (
        <Box as="section" className={data?.ClasseCss} my={'100px'}>
            <Container maxW={containerBreakpoints}>
                {data?.Subtitulo && 
                <Text className={`${data?.ClasseCss}-subtitulo`} as='h5' _before={getSubtitleDecoration()} pos='relative' pt={'16px'} textAlign={getTextAlign()} w={'100%'} fontSize={'14px'} lineHeight={1.2} textTransform={'uppercase'} letterSpacing={'1.4px'} fontWeight={'500'} margin={'0 0 7px'} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                    {data?.Subtitulo}
                </Text>
                }
                {data?.Titulo && 
                <Heading  className={`${data?.ClasseCss}-titulo`} mb={10} fontSize={'50px'} letterSpacing={'-2px'} textAlign={getTextAlign()} fontWeight={800} fontFamily={"'Plus Jakarta Sans', sans-serif"}>
                    {data?.Titulo}
                </Heading>
                }
                <InnerHtml className={`${data?.ClasseCss}-descricao`} fontSize={'17px'} lineHeight={'29px'} pb={'10px'} fontWeight={500} textAlign={getTextAlign()} html={data?.Descricao} />
                
                <form ref={form} action={submitForm}>
                    {fields.map((field: any, index: number) => {
                        return (
                            getFormField(field, index)
                        )
                    }
                    )}
                    { !loading && <CustonButton type="submit" as="button">Enviar</CustonButton> }
                    { loading && <Text>Enviando...</Text> }
                </form>
            </Container>
        </Box>
    )
}

export default ConteudoFormulario
