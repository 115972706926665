'use client'
import { Box, Heading, Center, Text, Button  } from '@chakra-ui/react'
import Image from 'next/image'
import Link from 'next/link'

const Error404Page = () => {
    return (
        <Center h={'100vh'} w={'100vw'} bg={'gray.100'} >
        <Box textAlign="center" py={10} px={6} className="bt-404">
            <Heading
                display="inline-block"
                as="h2" 
                size="2xl"
                bg="#c80003"
                backgroundClip="text">
                <Box>
                    <Link href={'/'}>
                    <Image style={{ width: 142, height: 56 }} width={142} height={56} title={'Brasal'} alt={'Brasal'} className="logo-portal" src={'https://dr7dcgej7t392.cloudfront.net/uploads/logo_brasal_30fc5c32a2.png'} />
                    </Link>
                </Box>
            </Heading>

            <Text fontSize="22px" fontWeight="800" mt={3} mb={2}>
                Página Não Encontrada
            </Text>

            <Text color={'gray.500'} mb={6}>
                A página que você está procurando não existe ou foi removida.
            </Text>
            <Link href={'/'}>
                <Button
                    colorScheme="red"
                    bg="#c80003"
                    color="white"
                variant="solid">
                    Ir para Página Inicial
                </Button>
            </Link>
            </Box>
            <Box className='video-decoration-top-right' position={'absolute'} top={0} right={0} background="url(https://dr7dcgej7t392.cloudfront.net/uploads/bg_top_arco_e41cbfe69d.webp) no-repeat" w={126} h={128} zIndex={3} />
            <Box className='video-decoration-bottom-right' position={'absolute'} bottom={0} right={0} background="url(https://dr7dcgej7t392.cloudfront.net/uploads/bg_bottom_arco_09ebaa3e19.webp) no-repeat" w={505} h={386} />
            <Box className='video-decoration-bottom-left' position={'absolute'} bottom={{base: '-70%', sm: '-70%', md:0}} left={0} background="url(https://dr7dcgej7t392.cloudfront.net/uploads/bg_bottom_left_a4d0c6e9f0.webp) no-repeat" w={{base: 643, sm: 643, md: 823}} h={{base: '100%', sm: '100%', md: 386}} backgroundSize={'contain'}/>
        </Center>
        
    );
}
export default Error404Page;
